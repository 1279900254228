import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import React, { ReactElement, useState } from "react";
import { ThemeProvider } from "styled-components";

import { useBreakpoint, useIsInEditMode } from "hooks";
import {
    addEditAttributes,
    addEditAttributesBlockLevel,
} from "utils/episerver";

import {
    Container,
    Heading,
    HeadingText,
    Button,
    Description,
    ExpandIcon,
    ContentWrapper,
    Wrapper,
    Introduction,
    StyledTextBlock,
} from "./Accordion.styled";
import AccordionProps from "./AccordionProps";

const Accordion = ({
    heading,
    introduction,
    mainBody,
    open,
    id,
    anchorName,
    contentId,
}: AccordionProps): ReactElement => {
    const [isActive, setIsActive] = useState(open);
    const sendDataToGTM = useGTMDispatch();
    const isInEditMode = useIsInEditMode();

    const handleKeyPress = (
        event: React.KeyboardEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        if (event?.key === "Enter") setIsActive(isInEditMode || !isActive); // eslint-disable-line
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (!isActive) {
            sendDataToGTM({
                event: "accordion_click",
                click_text: heading,
            });
        }
        setIsActive(isInEditMode || !isActive); // eslint-disable-line
    };

    let expandIcon: string, collapseIcon: string;
    switch (useBreakpoint()) {
        case "XL":
            [expandIcon, collapseIcon] = ["expand56", "collapse56"];
            break;
        case "L":
            [expandIcon, collapseIcon] = ["expand42", "collapse42"];
            break;
        case "M":
            [expandIcon, collapseIcon] = ["expand42", "collapse42"];
            break;
        default:
            [expandIcon, collapseIcon] = ["expand35", "collapse35"];
    }

    return (
        <ThemeProvider
            theme={{
                open: isActive || isInEditMode,
            }}
        >
            <Wrapper id={anchorName || id}>
                <Container {...addEditAttributesBlockLevel(contentId)}>
                    <Heading>
                        <Button
                            onClick={(event) => handleClick(event)}
                            onKeyPress={(event) => handleKeyPress(event)}
                            aria-controls={`accordion-panel-${id}`}
                            id={`accordion-header-${id}`}
                            tabIndex={0}
                            aria-expanded={isActive || isInEditMode}
                        >
                            <HeadingText
                                data-cy="heading"
                                {...addEditAttributes("Heading")}
                                dangerouslySetInnerHTML={{
                                    __html: heading || "",
                                }}
                            />

                            <ExpandIcon
                                key={expandIcon}
                                icon={
                                    isActive || isInEditMode
                                        ? collapseIcon
                                        : expandIcon
                                }
                                aria-hidden="true"
                            />
                        </Button>
                    </Heading>
                    <ContentWrapper
                        data-cy="contentWrapper"
                        aria-labelledby={`accordion-header-${id}`}
                        id={`accordion-panel-${id}`}
                        aria-hidden={!isActive && !isInEditMode}
                    >
                        {(isInEditMode || introduction) && (
                            <Introduction
                                {...addEditAttributes("Introduction")}
                                dangerouslySetInnerHTML={{
                                    __html: introduction || "",
                                }}
                            />
                        )}
                        {(isInEditMode || mainBody) && (
                            <Description {...addEditAttributes("MainBody")}>
                                {mainBody && (
                                    <StyledTextBlock
                                        content={mainBody}
                                        areaName="mainBody"
                                        editorialGrid={true}
                                    />
                                )}
                            </Description>
                        )}
                    </ContentWrapper>
                </Container>
            </Wrapper>
        </ThemeProvider>
    );
};

export default React.memo(Accordion);
