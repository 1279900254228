import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import TextBlock from "components/TextBlock";
import { Container as GridContainer } from "style/components/Page";
import {
    styleBodyLAlt,
    styleHeadingL,
    styleHeadingXL,
} from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const borderColor = theme("theme", {
    black: "--line-on-image-default",
    lightgray: "--line-on-neutral-default",
    blue: "--line-on-blue-default",
    cyan: "--line-on-cyan-default",
    image: "--line-on-image-default",
});

export const Wrapper = styled.div`
    align-items: center;
    border-top: 1px solid var(${borderColor});
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    &:last-of-type {
        border-bottom: 1px solid var(${borderColor});
    }
`;

export const Container = styled(GridContainer)`
    ${columnGap}
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding: 0 1.75rem;
    transition: padding 0.2s ease-in-out;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding: 0 2.1875rem;
    }

    ${MQ.FROM_XL} {
        padding: 0 3.5rem;
    }
`;

export const Heading = styled.h2`
    grid-column: 1 col-start / span 4;
    margin: 0;

    ${MQ.FROM_M} {
        grid-column: 1 col-start / span 12;
    }
`;

export const HeadingText = styled.span`
    ${theme("open", {
        true: styleHeadingXL,
        false: styleHeadingL,
    })};
    grid-column: col-start 1 / span 3;
    transition: font-size 0.2s ease-in-out;
    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 9;
    }
`;

export const Button = styled.button`
    align-items: center;
    all: unset;
    cursor: pointer;
    display: grid;
    ${columnGap}
    grid-column: col-start 1 / span 4;
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding: ${theme("open", {
        true: "2.1875rem 0",
        false: "1.3125rem 0",
    })};
    transition:
        padding 0.2s ease-in-out,
        font-size 0.2s ease-in-out;
    user-select: none;
    width: 100%;

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 12;
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding: ${theme("open", {
            true: "3.5rem 0 2.625rem 0",
            false: "1.75rem 0",
        })};
    }

    ${MQ.FROM_XL} {
        padding: ${theme("open", {
            true: "4.375rem 0 3.5rem 0",
            false: "2.1875rem 0",
        })};
    }
`;

export const ExpandIcon = styled(Icon)`
    grid-column: -2 / -1;
    place-self: start right;
    position: relative;
`;

export const ContentWrapper = styled.div`
    ${columnGap}
    display: grid;
    grid-column: col-start 1 / span 4;
    grid-template-columns: repeat(4, [col-start] 1fr);
    ${theme("open", {
        false: css`
            display: none;
        `,
    })};

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 12;
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const Introduction = styled.p`
    ${styleBodyLAlt}
    grid-column: col-start 1 / span 4;
    margin: 0 0 2.1875rem;

    ${MQ.FROM_M} {
        grid-column: col-start 5 / span 8;
        margin-bottom: 2.625rem;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 5 / span 7;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 6 / span 6;
        margin-bottom: 3.5rem;
    }
`;

export const Description = styled.section`
    grid-column: col-start 1 / span 4;
    margin: 0 0 4.375rem;

    ${MQ.FROM_M} {
        grid-column: col-start 5 / span 8;
        margin-bottom: 6.125rem;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 5 / span 8;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 6 / span 7;
        margin-bottom: 8.75rem;
    }
`;

export const StyledTextBlock = styled(TextBlock)`
    div {
        &:first-child :first-child {
            margin-top: 0;
        }

        &:last-child :last-child {
            margin-bottom: 0;
        }
    }
`;
